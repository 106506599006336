import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const GrupoPermissaoPage = lazy(() =>
	import("./grupo-permissao/GrupoPermissaoPage")
);

const UsuarioPage = lazy(() =>
	import("./usuario/UsuarioPage")
);

const PerfilPage = lazy(() =>
	import("./perfil/PerfilPage")
);

const AgendamentoPage = lazy(() =>
	import("./agendamento/AgendamentoPage")
);

const CaixaPage = lazy(() =>
	import("./caixa/CaixaPage")
);

const ComandaPage = lazy(() =>
	import("./comanda/ComandaPage")
);

const ComandaProdutoPage = lazy(() =>
	import("./comanda-produto/ComandaProdutoPage")
);

const PlanoPage = lazy(() =>
	import("./plano/PlanoPage")
);

const ServicoPage = lazy(() =>
	import("./servico/ServicoPage")
);

const ProdutoPage = lazy(() =>
	import("./produto/ProdutoPage")
);

const FilialPage = lazy(() =>
	import("./filial/FilialPage")
);

const ClientePage = lazy(() =>
	import("./cliente/ClientePage")
);

const RelatorioPage = lazy(() =>
	import("./relatorio/RelatorioPage")
);

const EstoquePage = lazy(() =>
	import("./estoque/EstoquePage")
);

const CategoriaPage = lazy(() =>
	import("./categoria/CategoriaPage")
);

const PreviaPage = lazy(() =>
	import("./previa/PreviaPage")
);

const TermosUsoPage = lazy(() =>
	import("./termos-uso/TermosUsoPage")
);

const PoliticasPrivacidadePage = lazy(() =>
	import("./politica-privacidade/PoliticaPrivacidadePage")
);

const VersoesPage = lazy(() =>
	import("./versoes/VersoesPage")
);

export default function HomePage() {

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					<Redirect exact from="/" to="/dashboard" />
				}

				<Route path="/dashboard" component={DashboardPage} />
				<Route path="/agendamento" component={AgendamentoPage} />
				<Route path="/caixa" component={CaixaPage} />
				<Route path="/comandas" component={ComandaPage} />
				<Route path="/comanda-produto" component={ComandaProdutoPage} />
				<Route path="/plano" component={PlanoPage} />
				<Route path="/servico" component={ServicoPage} />
				<Route path="/produtos" component={ProdutoPage} />
				<Route path="/filial" component={FilialPage} />
				<Route path="/cliente" component={ClientePage} />
				<Route path="/relatorio" component={RelatorioPage} />
				<Route path="/usuario" component={UsuarioPage} />
				<Route path="/perfil" component={PerfilPage} />
				<Route path="/grupo-permissao" component={GrupoPermissaoPage} />
				<Route path="/estoque" component={EstoquePage} />
				<Route path="/categorias" component={CategoriaPage} />
				<Route path="/previa" component={PreviaPage} />
				<Route path="/termos-de-uso" component={TermosUsoPage} />
				<Route path="/politica-de-privacidade" component={PoliticasPrivacidadePage} />
				<Route path="/versoes" component={VersoesPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
